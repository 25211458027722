import { Injectable, inject } from '@angular/core';
import { ApiService } from '@core/service/api';
import {
    ParamsOrders,
    Orders,
    ParamsOrdersDetail,
    OrdersDetail,
    ParamsOrdersCancelReasons,
    OrdersCancelReasons,
    ParamsOrdersMeta,
    OrdersMeta,
    ParamsOrdersStatuses,
    OrdersStatuses,
    ParamsOrdersTestCreate,
    OrdersTestCreate,
} from './interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    OrdersMonths,
    OrdersMonthsService,
    ParamsOrdersMonths,
} from '@shared/service/methods/orders';

@Injectable({ providedIn: 'any' })
export class ApiOrdersService implements OrdersMonthsService {
    private _apiService = inject(ApiService);

    list(
        obj?: ParamsOrders,
        needPagination?: boolean,
        acceptableValues?: string[]
    ): Observable<Orders> {
        return this._apiService
            .get(
                'merch-lk/v1/orders',
                this._apiService.prepareParams(
                    obj,
                    needPagination,
                    acceptableValues
                )
            )
            .pipe(map((res) => res.json));
    }

    detail(obj: ParamsOrdersDetail): Observable<OrdersDetail> {
        return this._apiService
            .get('merch-lk/v1/orders/detail', obj)
            .pipe(map((res) => res.json));
    }

    months(obj?: ParamsOrdersMonths): Observable<OrdersMonths> {
        return this._apiService
            .get('merch-lk/v1/orders/months', obj)
            .pipe(map((res) => res.json));
    }

    cancelReasons(
        obj?: ParamsOrdersCancelReasons
    ): Observable<OrdersCancelReasons> {
        return this._apiService
            .get('merch-lk/v1/orders/cancel-reasons', obj)
            .pipe(map((res) => res.json));
    }

    meta(obj?: ParamsOrdersMeta): Observable<OrdersMeta> {
        return this._apiService
            .get('merch-lk/v2/orders/meta', obj)
            .pipe(map((res) => res.json));
    }

    download(obj?: ParamsOrders): Observable<void> {
        return this._apiService
            .get('merch-lk/v1/orders/download', obj, true)
            .pipe(map((res) => this._apiService.downloadFile(res, 'orders')));
    }

    statuses(obj?: ParamsOrdersStatuses): Observable<OrdersStatuses> {
        return this._apiService
            .get('merch-lk/v1/orders/statuses', obj)
            .pipe(map((res) => res.json));
    }

    createTest(obj: ParamsOrdersTestCreate): Observable<OrdersTestCreate> {
        return this._apiService
            .post('merch-lk/v1/orders/test/create', obj)
            .pipe(map((res) => res.json));
    }
}
